const GERMAN = {
  belgium: 'Belgien',
  germany: 'Deutschland',
  luxembourg: 'Luxemburg',
  netherlands: 'Niederlande',
  Animate_years: 'Jahre animieren',
  Close : 'Stopp',
  Country : 'Land',
  Female: 'Weiblich',
  Male: 'Männlich',
  No: 'Nein',
  Pups: 'Welpen',
  Region: 'Region',
  Reproduction: 'Fortpflanzung',
  Restart: 'Neustart',
  Status: 'Status',
  Stop_animation: 'Animation anhalten',
  Territory: 'Territorium',
  Year: 'Jahr',
  Yes: 'Ja',
  about_this_map: 'Über diese Karte',
  all_territories_from: 'Alle Territorien von',
  couple: 'Paar',
  couples: 'Paare',
  data_benelux_germany : 'Daten Benelux und Deutschland bis Mai 2022',
  individual: 'Einzeltier',
  individuals: 'Einzeltiere',
  more_info: 'Mehr Infos',
  pack: 'Rudel',
  packs: 'Rudel',
  press_enter_to_select: 'Drücken Sie Enter zur Auswahl',
  pups: 'Welpen',
  reproduction_took_place_in : 'Die Reproduktion fand statt in',
  select_region: 'Wählen Sie eine Region',
  select_territory: 'Wählen Sie ein Territorium',
  select_country: 'Wählen Sie ein Land',
  selected: 'Ausgewählt',
  territorial_wolves_regions: 'Territoriale Wölfe lebten in den folgenden Regionen',
  with_a_total_of: 'mit einer Gesamtzahl von',
  wolfterritories: 'Wolfsterritorien',
  please_note: 'Bitte beachten: Die Daten für dieses Monitoringjahr sind noch nicht vollständig! Die Karte zeigt nur die Daten bis Mai 2022',
  info_text: 'Die Karte zur Verbreitung der Wölfe in den Niederlanden, Belgien, Luxemburg und Deutschland ist durch eine Zusammenarbeit von Behörden und/oder der mit dem Wolfsmonitoring beauftragten Institutionen der Länder entstanden. Die Daten liegen in der Verantwortung dieser Länder und werden gemäß ihrem jeweiligen Monitoringsstandards zum Wolf erhoben. Die Daten werden jedes Jahr im November aktualisiert. Dabei werden die Daten bis zum 1. Mai dieses Jahres hinzugefügt und gegebenenfalls Daten der vorherigen Jahre ergänzt.',
  nederland_bij12: 'Niederlanden: BIJ12 ',
  belgie_anb: 'Belgien: Agentschap voor Natuur en Bos (ANB) und Service public Wallonie (SPW)',
  luxemburg_anf: 'Luxembourg: Administration de la nature et des forêts (ANF)',
  duitsland_dbww: 'Deutschland: DBBW, <a target="_blank" class="hover:text-red underline" href="https://www.dbb-wolf.de">www.dbb-wolf.de</a>, data heruntergeladen am 19. Januar 2023',
  copyright: '© BIJ12, ANB, SPW, ANF, DBBW, Januar 2023',
  funded: 'Dieses Projekt wurde durch das <a target="_blank" class="hover:text-red underline" href="https://www.eurolargecarnivores.eu">EuroLargeCarnivores LIFE project</a> und das Niederländisches Ministerium für Landwirtschaft, Natur und Lebensmittelqualität finanziert und ermöglicht.',
  download_data: 'Daten herunterladen'
}

export { GERMAN };