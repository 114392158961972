<template>
  <div class="w-full h-full relative">
    <wolf-years
        :texts="texts"
        class="m-0 p-0 absolute left-0 right-0 top-0 z-20 bg-white border-b-2 border-bordercolor"
        :years="years"
        :year-names="yearNames"
        :active-year="year"
        :animating="animating"
        @select-year="selectYear"
        @start-animating="startAnimating"
        @stop-animating="animating = false"
        @restart="restart"
    >
    </wolf-years>
    <div class="w-full h-full relative md:pt-9">
      <div class="w-full h-full relative">

        <div id="map" class="w-full h-full z-10"></div>

        <wolf-card
            class="absolute bottom-10 right-2 md:right-10 md:bottom-auto md:top-0 z-20"
        >
          <language-switcher
              :locales="locales"
              :locale="locale"
              @set-locale="setLocale">
          </language-switcher>
          <div
              class="md:hidden">
          <vue-multiselect
              :allow-empty="false"
              class=" md:hidden mb-2 cursor-pointer"
              style="width:250px"
              v-model="year"
              deselectLabel=""
              @input="selectTerritoriumFromSelect"
              :options="years"
              :customLabel="customLabel"
              :selectLabel="texts.press_enter_to_select"
              :selectedLabel="texts.selected"
              placeholder="Year">
            <template slot="clear">
              <div class="multiselect__clear absolute right-10 z-40 top-2.5 cursor-pointer" v-if="selectedTerritorium" @mousedown.prevent.stop="clearSelectedTerritorium"><i class="icon icon-cross text-lightgrey"></i></div>
            </template>
          </vue-multiselect>
          </div>
          <vue-multiselect
              :allow-empty="false"
              class="mb-2 cursor-pointer"
              style="width:250px"
              v-model="selectedCountry"
              deselectLabel=""
              @input="selectCountryFromSelect"
              :options="countries"
              track-by="id"
              label="name"
              :selectLabel="texts.press_enter_to_select"
              :selectedLabel="texts.selected"
              :placeholder="texts.select_country">
            <template slot="clear">
              <div class="multiselect__clear absolute right-10 z-40 top-2.5 cursor-pointer" v-if="selectedCountry" @mousedown.prevent.stop="clearSelectedCountry"><i class="icon icon-cross text-lightgrey"></i></div>
            </template>
          </vue-multiselect>
          <vue-multiselect
              class="mb-2 cursor-pointer"
              style="width:250px"
              v-model="selectedRegion"
              :options="regions"
              group-values="regions"
              group-label="country"
              track-by="name"
              label="name"
              deselectLabel=""
              @input="selectRegionFromSelect"
              :selectLabel="texts.press_enter_to_select"
              :selectedLabel="texts.selected"
              :placeholder="texts.select_region">
            <template slot="clear">
              <div class="multiselect__clear absolute right-10 z-40 top-2.5 cursor-pointer" v-if="selectedRegion" @mousedown.prevent.stop="clearSelectedRegion"><i class="icon icon-cross text-lightgrey"></i></div>
            </template>
          </vue-multiselect>
          <vue-multiselect
              class="cursor-pointer"
              style="width:250px"
              v-model="selectedTerritorium"
              group-values="territoria"
              group-label="region"
              track-by="name"
              label="name"
              deselectLabel=""
              @input="selectTerritoriumFromSelect"
              :options="territoria"
              :selectLabel="texts.press_enter_to_select"
              :selectedLabel="texts.selected"
              :placeholder="texts.select_territory">
            <template slot="clear">
              <div class="multiselect__clear absolute right-10 z-40 top-2.5 cursor-pointer" v-if="selectedTerritorium" @mousedown.prevent.stop="clearSelectedTerritorium"><i class="icon icon-cross text-lightgrey"></i></div>
            </template>
          </vue-multiselect>
        </wolf-card>

        <div class="absolute left-0 top-0 z-20">
          <wolf-card
          class="font-semibold">
            <div class="text-xl">
              {{texts.wolfterritories}}
              <span v-if="region"
                    v-html="'- ' + region"></span>
              {{ yearNames[year] }}
            </div>
            <div v-html="texts.data_benelux_germany"></div>
            <div
                class="font-normal max-w-sm"
                v-html="texts.please_note"></div>
          </wolf-card>
          <wolf-card
              v-if="showDetails"
              v-show="activeFeatures.features && activeFeatures.features.length"
              class="mt-2 inline-block  max-w-[160px]  md:max-w-sm "
          >
            <wolf-details
                :texts="texts"
                :year="year"
                :year-names="yearNames"
                :features="activeFeatures.features"
                :region="region"
                @select-region="selectRegion"
                @hide-details="showDetails = false"
            ></wolf-details>
          </wolf-card>
        </div>

        <div class="absolute left-0 right-0 md:right-auto bottom-0 z-10">
          <wolf-card
              class="font-semibold">
            <wolf-legend
                :texts="texts"
                @show-info="infoVisible = true"></wolf-legend>

          </wolf-card>
        </div>
      </div>
    </div>





    <wolf-modal
        :texts="texts"
        class="absolute left-0 bottom-0 z-100"
        v-if="modalVisible"
        @close="modalVisible = false">
      <template v-slot:header>
        <div class="mb-2 font-semibold">
          <div v-if="!observedTerritorium">
            <h3 class="text-xl">
              {{ texts.all_territories_from }} {{observedRegion}}
            </h3>
          </div>
          <div v-else>
            <h3 class="text-xl">
              {{ texts.Territory }} {{observedTerritorium}}
            </h3>
            <h2>
              {{ texts.Region }}: {{observedRegion}}
            </h2>
          </div>
        </div>
      </template>
      <wolf-observations
          :texts="texts"
          :features="observed"
          :region="observedRegion"
          :territorium="observedTerritorium"
          :year-names="yearNames"
          v-if="observed.length"
          @close="observed = []"
          @select-region="selectRegionFromObservations"
          @select-territorium="selectTerritoriumFromObservations"
      >
        >
      </wolf-observations>
    </wolf-modal>
    <wolf-modal
        :texts="texts"
        class="absolute left-0 bottom-0 z-100"
        v-if="infoVisible"
        @close="infoVisible = false">
      <template v-slot:header>
        <div class="mb-2 font-semibold" v-html="texts.about_this_map">
        </div>
      </template>
      <p class="mb-4" v-html="texts.info_text">
      </p>
      <p v-html="texts.nederland_bij12"></p>
      <p v-html="texts.belgie_anb"></p>
      <p v-html="texts.luxemburg_anf"></p>
      <p class="mb-4" v-html="texts.duitsland_dbww"></p>
      <p class="mb-4" v-html="texts.funded"></p>
<!--      <p class="mb-4"><a class="hover:underline" download="" target="_blank" href="https://wolvesmap.zoogdiervereniging.nl/data/territories.geojson"><i class="icon icon-download mr-1 "></i>{{texts.download_data}}</a></p>-->

      <p class="mb-4" v-html="texts.copyright"></p>

      <div class="flex mt-8 -mx-1 items-center">
        <div class="h-12 w-1/6 p-1 flex items-center justify-center">
          <a target="_blank" href="https://www.natuurenbos.be/wolven"><img class="max-h-12 w-auto" src="/logos/Vlaanderen_verbeelding werkt.png" alt=""></a>
        </div>
        <div class="h-12 w-1/6 p-1 flex items-center justify-center">
          <a target="_blank" href="http://biodiversite.wallonie.be/fr/reseau-loup.html?IDC=6413"><img class="max-h-12 w-auto" src="/logos/wallonie_v.png" alt=""></a>
        </div>
        <div class="h-12 w-1/6 p-1 flex items-center justify-center">
          <a target="_blank" href="https://www.bij12.nl/onderwerpen/faunazaken/diersoorten/wolf/"><img class="max-h-12 w-auto" src="/logos/120413_IPO_BIJ12_Logo_Wide_RGB.png" alt=""></a>
        </div>
        <div class="h-12 w-1/6 p-1 flex items-center justify-center">
          <a target="_blank" href="https://www.bfn.de/"><img class="max-h-12 w-auto" src="/logos/RZ_Logo BfN 2014_transparent_EN.png" alt=""></a>
        </div>
        <div class="h-12 w-1/6 p-1 flex items-center justify-center">
          <a target="_blank" href="https://www.dbb-wolf.de/home"><img class="max-h-12 w-auto" src="/logos/DBBW_logo.png" alt=""></a>
        </div>


        <div class="h-12 w-1/6 p-1 flex items-center justify-center">
          <a target="_blank" href="https://anf.gouvernement.lu/"><img class="max-h-12 w-auto" src="/logos/ANF_LOGO.png" alt=""></a>
        </div>
      </div>
    </wolf-modal>


  </div>

</template>

<script>

import axios from 'axios';
import {featureCollection} from '@turf/helpers'
import CenterOfMass from '@turf/center-of-mass'

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@/assets/icomoon/style.css';
import WolfYears from "@/components/WolfYears";
import WolfObservations from "@/components/WolfObservations";
import WolfDetails from "@/components/WolfDetails";
import WolfCard from "@/components/WolfCard";
import WolfModal from "@/components/WolfModal";
import WolfLegend from "@/components/WolfLegend";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import PopupComponent from "@/components/PopupComponent";
import Vue from "vue";
import {DUTCH} from "@/dutch";
import {ENGLISH} from "@/english"
import {FRENCH} from "@/french";
import {GERMAN} from "@/german";
/* This code is needed to properly load the images in the Leaflet CSS */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import 'leaflet.fullscreen/Control.FullScreen.js';
import 'leaflet.fullscreen/Control.FullScreen.css';

import { YEARS, YEARNAMES, COLORS } from '@/variables'


export default {
  name: "WolfMap",
  components: {
    WolfYears,
    WolfObservations,
    WolfDetails,
    WolfCard,
    WolfModal,
    LanguageSwitcher,
    WolfLegend
  },
  data: function () {
    return {
      animating:false,
      news: [],
      activeNews: [],
      activeFeatures: featureCollection([]),
      map: undefined,
      markerLayer: undefined,
      geojson: {},
      years: YEARS,
      yearNames: YEARNAMES,
      year: YEARS[YEARS.length - 1],
      region: undefined,
      selectedRegion:undefined,
      selectedTerritorium: undefined,
      selectedCountry: undefined,
      regions: [],
      countries: [],
      territoria: [],
      territorium: null,
      country: null,
      observed: [],
      observedRegion: undefined,
      observedTerritorium: undefined,
      modalVisible: false,
      infoVisible:false,
      showDetails:true,
      locales: [
        {
          name: 'DE',
          code: 'de'
        },
        {
          name: 'EN',
          code: 'en'
        },
        {
          name: 'FR',
          code: 'fr'
        },
        {
          name: 'NL',
          code: 'nl'
        }
      ],
      locale: 'en',
      textfiles: {
        nl: DUTCH,
        en: ENGLISH,
        fr: FRENCH,
        de: GERMAN
      },
    }
  },
  watch: {
    year() {
      // this.buildObserved();
      this.rebuildMapData(false);

    },
    country(country) {
      this.buildObserved();
      this.rebuildMapData();
      if(country) {
        this.selectedCountry = country;
      } else {
        this.selectedCountry = undefined;
      }

    },
    region(region) {
      this.buildObserved();
      this.rebuildMapData();
      if(region) {
        this.selectedRegion = {
          name:region
        };
      } else {
        this.selectedRegion = null;
      }

    },
    territorium(territorium) {
      this.rebuildMapData(false);
      if(territorium) {
        this.selectedTerritorium = {
          name:territorium
        };
      } else {
        this.selectedTerritorium = null;
      }
    },
  },
  computed: {
    texts() {
      return this.textfiles[this.locale]
    },
  },
  methods: {
    customLabel(year) {
      return this.yearNames[year];
    },
    restart() {
      this.animating = false;
      this.year = this.years[this.years.length - 1];
      this.territorium = null;
      this.region = null;
      this.rebuildMapData();
    },
    buildObserved() {
      if (this.observedTerritorium) {
        this.observed = this.geojson.features.filter(feature => feature.properties.Territorium == this.observedTerritorium);
      }
      else {
        this.observed = this.geojson.features.filter(feature => feature.properties.Region == this.observedRegion).sort((a, b) => (a.properties.Territorium.localeCompare(b.properties.Territorium) || a.properties.Mjahr < b.properties.Mjahr));
      }

    },
    rebuildMapData(rebuild = true) {

      this.markerLayer.clearLayers();
      this.addActiveFeatures();
      if (rebuild) {
        this.showDetails=true;
        this.fitBounds();
      }

    },
    addGeojson(data) {
      this.geojson = data;
      this.addRegions();
      this.addTerritoria();
      this.rebuildMapData();
    },
    addRegions() {
      this.regions = this.geojson.features.reduce((array, feature) => this.createTerritorium(array, feature), []).sort((a, b) => (a.country.localeCompare(b.country) || a.region.localeCompare(b.region)));
    },
    createTerritorium(array, feature) {
        let country = array.find(item => item.country == feature.properties.Country);
        if (!country) {
          array.push({
            country: feature.properties.Country,
            regions:[
              {name:feature.properties.Region}
            ]
          });
        } else if(typeof country.regions.find(item => item.name ==feature.properties.Region) == 'undefined') {
          country.regions.push({name:feature.properties.Region});
        }
        return array;
    },
    addTerritoria() {
      this.territoria = this.geojson.features.reduce((array, feature) => this.createRegion(array, feature), [])
          .sort((a, b) => (a.country.localeCompare(b.country) || a.region.localeCompare(b.region)))
          .map(item => this.mapRegion(item));
    },
    createRegion(array, feature) {
      let region = array.find(item => item.region == feature.properties.Region);
      if (!region) {
        array.push({
          region: feature.properties.Region,
          country: feature.properties.Country,
          territoria:[
            {name:feature.properties.Territorium}
          ]
        });
      } else if(typeof region.territoria.find(item => item.name ==feature.properties.Territorium) == 'undefined') {
        region.territoria.push({name:feature.properties.Territorium});
      }
      return array;
    },
    mapRegion(item) {
      return {
        region: item.region,
        country: item.country,
        territoria:item.territoria.sort((a, b) => (a.name.localeCompare(b.name)))
      }
    },

    addActiveFeatures() {
      let features = this.geojson.features.filter(feature =>
          feature.properties.Mjahr == this.year &&
          (this.country ? feature.properties.Country == this.country.id : true) &&
          (this.region ? feature.properties.Region == this.region : true) &&
          (this.territorium ? feature.properties.Territorium == this.territorium : true)
      );

      let territoria = this.groupTerritoria(features);


      let newfeatures = [];

      Object.keys(territoria).forEach(key => newfeatures.push(this.center(featureCollection(territoria[key]))));


      this.activeFeatures = featureCollection(newfeatures);

      this.addFeatures();


    },
    addFeatures() {
      let comp = this;
      L.geoJSON(comp.activeFeatures, {
        onEachFeature: (feature, layer) => layer.bindPopup(this.popup(feature)),
        pointToLayer:(feature, latlng) =>  L.circleMarker(latlng, comp.geojsonMarkerOptions(feature))
            .on('click', () => comp.clickMarker(feature))
            .on('mouseover', function() {this.openPopup()})
            .on('mouseout', function() {this.closePopup()})
      }).addTo(this.markerLayer);
    },
    groupTerritoria: function (features) {
      return features.reduce(function (array, feature) {
        (array[feature.properties.Territorium] = array[feature.properties.Territorium] || []).push(feature);
        return array
      }, {});
    },
    center(territorium) {
      return CenterOfMass(territorium, {
        properties: territorium.features[0].properties
      });
    },
    selectYear(year) {
      this.year = year
    },
    // selectCountry(country) {
    //   this.territorium = null;
    //   this.region = null;
    //   this.country = country;
    // },
    selectCountryFromSelect(country) {
      if(country) {
        this.territorium = null;
        this.region = null;
        this.country = country;
      } else {
        this.country = null;
      }
    },
    selectRegion(region) {
      this.country = null;
      this.territorium = null;
      this.region = region;
    },
    selectRegionFromObservations(region) {
      this.country = null;
      this.observedTerritorium = null;
      this.observedRegion = region;
      this.buildObserved();
    },
    selectRegionFromSelect(value) {
      if(value) {
        this.country = null;
        this.territorium = null;
        this.region = value.name;
        this.country = this.countries.find(country => country.id == this.geojson.features.find(feature => feature.properties.Region == value.name).properties.Country)
      } else {
        this.territorium = null;
        this.region = null;
      }
    },
    clearSelectedCountry() {
      this.territorium = null;
      this.region = null;
      this.country = null;
    },
    clearSelectedRegion() {
      this.territorium = null;
      this.region = null;
      this.country = null;
    },
    selectTerritoriumFromObservations(territorium) {
      this.country = null;
      this.observedTerritorium = territorium;
      this.buildObserved()
    },
    selectTerritoriumFromSelect(value) {
      if(value) {
        this.country = null;
        this.territorium = value.name;
        this.region = this.geojson.features.find(feature => feature.properties.Territorium == value.name).properties.Region
        this.country = this.countries.find(country => country.id == this.geojson.features.find(feature => feature.properties.Territorium == value.name).properties.Country)
      } else {
        this.territorium = null;
      }
    },
    clearSelectedTerritorium() {
      this.territorium = null;
    },
    clickMarker(clicked) {
      this.observed = this.geojson.features.filter(feature => feature.properties.Territorium == clicked.properties.Territorium).sort((a, b) => a.properties.Mjahr < b.properties.Mjahr);
      this.observedTerritorium = clicked.properties.Territorium;
      this.observedRegion = clicked.properties.Region;
      this.buildObserved();
      this.modalVisible = true;
    },
    geojsonMarkerOptions(feature) {
      return {
        radius: 0.08 * Math.pow(2,this.map.getZoom()),
        fillColor: this.fillColor(feature.properties.Status),
        color: "#000",
        weight: 0.5,
        opacity: 1,
        fillOpacity: 0.7
      };
    },
    fillColor(status) {
      if (status == 1) {
        return COLORS.blue
      }
      if (status == 2) {
        return COLORS.red
      }
      if (status == 3) {
        return COLORS.yellow
      }
    },
    setLocale(locale) {
      this.locale = locale;
      this.countries = [
        {
          'id': 'BE',
          'name': this.texts.belgium
        },
        {
          'id': 'DE',
          'name': this.texts.germany
        },
        {
          'id': 'LU',
          'name': this.texts.luxembourg
        },
        {
          'id': 'NL',
          'name': this.texts.netherlands
        },
      ];

      // translate selected country
      if(this.country) {
        this.selectedCountry = this.countries.find(country => country.id == this.selectedCountry.id);
      }

    },
    startAnimating() {
      this.animating = true;
      this.fitBounds();
    },
    fitBounds() {
      this.map.fitBounds(this.getBounds(), {
        maxZoom: 10,
        padding: this.map.getZoom() > 10 ? [40,40] : [10, 10]
      });
    },
    getBounds() {
      return this.region ? new L.geoJson(this.geojson.features.filter(feature => feature.properties.Region == this.region)).getBounds() :  new L.geoJson(this.geojson).getBounds();
    },
    popup(feature) {
      let PopupClass = Vue.extend(PopupComponent);
      let instance = new PopupClass({
        propsData: {
          "feature": feature,
          'yearNames': this.yearNames,
          'texts': this.texts
        }
      });
      instance.$mount();
      return instance.$el;
    },

    mapOptions() {
      const ios = () => {
        if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

        return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && window.opera.toString() === `[object Opera]`));
      };

      return ios() ? {
        zoomControl: false
      } : {
        fullscreenControl: true,
        fullscreenControlOptions: {
          fullscreenElement: document.getElementById('app'),
          position: 'topright',
        },
        zoomControl: false
      };
    }
  },
  mounted() {

    this.countries = [
      {
        'id': 'BE',
        'name': this.texts.belgium
      },
      {
        'id': 'DE',
        'name': this.texts.germany
      },
      {
        'id': 'LU',
        'name': this.texts.luxembourg
      },
      {
        'id': 'NL',
        'name': this.texts.netherlands
      },
    ];

    this.map = L.map('map', this.mapOptions())
    this.markerLayer = new L.FeatureGroup().addTo(this.map);
    const defaultCenter = [52, 5];
    const defaultZoom = 7;
    const basemap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    this.map.setView(defaultCenter, defaultZoom);




    L.control.zoom({
      position: 'topright'
    }).addTo(this.map);

    L.control.scale({
      position: 'bottomright'
    }).addTo(this.map);

    this.map.on('zoomend', () => this.rebuildMapData(false));

    basemap.addTo(this.map);

    axios.get('https://wolvesmap.zoogdiervereniging.nl/data/territories_2021_2022.geojson')
        .then(response =>
        {
          this.addGeojson(response.data)
        })
        .catch(error => console.log(error));
  }
}
</script>

<style scoped>

</style>