const ENGLISH = {
  belgium: 'Belgium',
  germany: 'Germany',
  luxembourg: 'Luxembourg',
  netherlands: 'Netherlands',
  Animate_years: 'Play years',
  Close : 'Close',
  Country : 'Country',
  Female: 'Female',
  Male: 'Male',
  No: 'No',
  Pups: 'Pups',
  Region: 'Region',
  Reproduction: 'Reproduction',
  Restart: 'Reset map',
  Status: 'Status',
  Stop_animation: 'Stop animation',
  Territory: 'Territory',
  Year: 'Year',
  Yes: 'Yes',
  about_this_map: 'About this map',
  all_territories_from: 'All Territories from',
  couple: 'pair',
  couples: 'pairs',
  data_benelux_germany : 'Data Benelux and Germany until May 2022',
  individual: 'individual',
  individuals: 'individuals',
  more_info: 'More info',
  pack: 'pack',
  packs: 'packs',
  press_enter_to_select: 'Press enter to select',
  pups: 'pups',
  reproduction_took_place_in : 'Reproduction took place in',
  select_region: 'Select a region',
  select_territory: 'Select a territory',
  select_country: 'Select a country',
  selected: 'Selected',
  territorial_wolves_regions: 'Territorial wolves lived in the following regions',
  with_a_total_of: 'with a total of',
  wolfterritories: 'Wolfterritories',
  please_note: 'Please note: The data of the current monitorings year are not yet complete! The map only shows the data until May 2022',
  info_text: 'The map of wolf distribution in the Netherlands, Belgium, Luxembourg and Germany is the result of a cooperation of the authorities and/or implementing organisations responsible for wolf monitoring in these countries. The data are the responsibility of these countries and are collected following their national wolf monitoring plan. The data are updated once a year in November. Then, the data until 1 May of that year are added. Data from previous years can also be added.',
  nederland_bij12: 'The Netherlands: BIJ12 on behalf of the Dutch provinces',
  belgie_anb: 'Belgium: Agentschap voor Natuur en Bos (ANB) and Service public Wallonie (SPW)',
  luxemburg_anf: 'Luxembourg: Administration de la nature et des forêts (ANF)',
  duitsland_dbww: 'Germany: DBBW, <a target="_blank" class="hover:text-red underline" href="https://www.dbb-wolf.de">www.dbb-wolf.de</a>, data downloaded on 19 January 2023',
  copyright: '© BIJ12, ANB, SPW, ANF, DBBW, January 2023',
  funded: 'This project has been funded and enabled via the <a target="_blank" class="hover:text-red underline" href="https://www.eurolargecarnivores.eu">EuroLargeCarnivores LIFE project</a> and the Dutch Ministry of Agriculture, Nature and Food Quality.',
  'download_data': 'Download data'

}

export { ENGLISH };