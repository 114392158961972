import { render, staticRenderFns } from "./WolfMap.vue?vue&type=template&id=75d779ba&scoped=true&"
import script from "./WolfMap.vue?vue&type=script&lang=js&"
export * from "./WolfMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d779ba",
  null
  
)

export default component.exports